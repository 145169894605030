



























































































































































@import '~@/assets/styles/components/modal-cadastro';

.br-btn-whatsapp {
  background-color: #00c70f !important;
  border-color: #00c70f !important;
}
